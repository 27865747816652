import request from '@/utils/request'; // 查询班车业务不同公司城市配置列表

export function listBusCityConfig(query) {
  return request({
    url: '/system/busCityConfig/list',
    method: 'get',
    params: query
  });
} // 查询班车业务不同公司城市配置详细

export function getBusCityConfig(id) {
  return request({
    url: '/system/busCityConfig/' + id,
    method: 'get'
  });
} // 新增班车业务不同公司城市配置

export function addBusCityConfig(data) {
  return request({
    url: '/system/busCityConfig',
    method: 'post',
    data: data
  });
} // 修改班车业务不同公司城市配置

export function updateBusCityConfig(data) {
  return request({
    url: '/system/busCityConfig',
    method: 'put',
    data: data
  });
} // 删除班车业务不同公司城市配置

export function delBusCityConfig(id) {
  return request({
    url: '/system/busCityConfig/' + id,
    method: 'delete'
  });
} // 导出班车业务不同公司城市配置

export function exportBusCityConfig(query) {
  return request({
    url: '/system/busCityConfig/export',
    method: 'get',
    params: query
  });
} // 获取所有公司

export function deptList() {
  return request({
    url: '/system/dept/accessibleDepts',
    method: 'get'
  });
}