import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/* Layout */

import Layout from "@/layout";
/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */
// 公共路由

export var constantRoutes = [{
  path: "/redirect",
  component: Layout,
  hidden: true,
  children: [{
    path: "/redirect/:path(.*)",
    component: function component() {
      return import("@/views/redirect");
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return import("@/views/login");
  },
  hidden: true
}, {
  path: "/dataAnalysisBus",
  component: function component() {
    return import("@/views/dataAnalysisBus");
  },
  hidden: true
}, {
  path: "/dataAnalysisCarpool",
  component: function component() {
    return import("@/views/dataAnalysisCarpool");
  },
  hidden: true
}, {
  path: "",
  component: Layout,
  redirect: "index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/index");
    },
    name: "首页",
    meta: {
      title: "首页",
      icon: "dashboard",
      noCache: true,
      affix: true
    }
  }, {
    path: "/carpoolManage/orderManage/orderDetail",
    component: function component() {
      return import("@/views/business/carpoolNew/orderDetail");
    },
    name: "OrderDetail",
    hidden: true,
    meta: {
      title: "订单详情"
    }
  }, {
    path: "/carpoolManage/orderManage/tripDetail",
    component: function component() {
      return import("@/views/business/carpoolNew/tripDetail");
    },
    name: "TripDetail",
    hidden: true,
    meta: {
      title: "行程详情"
    }
  }, {
    path: "/marketManage/coupon/send",
    component: function component() {
      return import("@/views/marketManage/couponNew/send");
    },
    name: "SendCoupon",
    hidden: true,
    meta: {
      title: "发放优惠券"
    }
  }, {
    path: "/marketManage/coupon/detail",
    component: function component() {
      return import("@/views/marketManage/coupon/detail");
    },
    name: "CouponDetail",
    hidden: true,
    meta: {
      title: "优惠券详情"
    }
  }, {
    path: "/marketManage/coupon/sendgift",
    component: function component() {
      return import("@/views/marketManage/coupon/sendgift");
    },
    name: "SendCouponGift",
    hidden: true,
    meta: {
      title: "发放优惠券礼包"
    }
  }, {
    path: "/financeCollect/driverBill/detail",
    component: function component() {
      return import("@/views/financeCollect/driverBill/detail");
    },
    name: "driverBillDetail",
    hidden: true,
    meta: {
      title: "加盟司机收益明细"
    }
  }, {
    path: "/system/orderManage/detail",
    component: function component() {
      return import("@/views/system/orderManage/detail");
    },
    name: "BusOrderDetail",
    hidden: true,
    meta: {
      title: "订单详情"
    }
  }]
}, {
  path: "/index",
  component: Layout // redirect: 'yewuguanli/busCountry/bus'

}, {
  path: "/user",
  component: Layout,
  hidden: true,
  redirect: "noredirect",
  children: [{
    path: "profile",
    component: function component() {
      return import("@/views/system/user/profile/index");
    },
    name: "Profile",
    meta: {
      title: "个人中心",
      icon: "user"
    }
  }]
}, {
  path: "/dict",
  component: Layout,
  hidden: true,
  children: [{
    path: "type/data/:dictId(\\d+)",
    component: function component() {
      return import("@/views/system/dict/data");
    },
    name: "Data",
    meta: {
      title: "字典数据",
      icon: ""
    }
  }]
}, // 机场
{
  path: "/airport",
  component: Layout,
  hidden: true,
  children: [{
    path: "airLineConfig",
    component: function component() {
      return import("@/views/airport/line/config");
    },
    name: "AirLineConfig",
    meta: {
      title: "线路配置",
      icon: ""
    }
  }, {
    path: "station/addUpdate",
    component: function component() {
      return import("@/views/airport/station/addUpdate");
    },
    name: "addUpdateAirStation",
    meta: {
      title: "站点"
    }
  }, {
    path: "order/detail",
    component: function component() {
      return import("@/views/airport/order/detail");
    },
    name: "AirOrderDetail",
    meta: {
      title: "订单详情"
    }
  }]
}, {
  path: "/carpool",
  component: Layout,
  hidden: true,
  children: [{
    path: "type/data/:lineId(\\d+)",
    component: function component() {
      return import("@/views/business/carpool/listConfig");
    },
    name: "CarpoolOperationalListConfig",
    meta: {
      title: "运营范围配置",
      icon: ""
    }
  }, {
    path: "carpoolNewOrder",
    component: function component() {
      return import("@/views/business/carpoolNew/carpoolNewOrder");
    },
    name: "CarpoolNewOrder",
    meta: {
      title: "新建订单",
      icon: ""
    }
  }, {
    path: "listConfig/:lineId(\\d+)",
    component: function component() {
      return import("@/views/business/carpoolNew/line/listConfig");
    },
    name: "CarpoolConfig",
    meta: {
      title: "线路配置",
      icon: ""
    }
  }]
}, {
  path: "/parcel",
  component: Layout,
  hidden: true,
  children: [{
    path: "type/data/:parcelId(\\d+)",
    component: function component() {
      return import("@/views/business/newParcel/configList");
    },
    name: "ParcelOperationalListConfig",
    meta: {
      title: "运营范围配置",
      icon: ""
    }
  }]
}, {
  path: "/job",
  component: Layout,
  hidden: true,
  children: [{
    path: "log",
    component: function component() {
      return import("@/views/monitor/job/log");
    },
    name: "JobLog",
    meta: {
      title: "调度日志"
    }
  }]
}, {
  path: "/gen",
  component: Layout,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return import("@/views/tool/gen/editTable");
    },
    name: "GenEdit",
    meta: {
      title: "修改生成配置"
    }
  }]
}, {
  path: "/passenger",
  component: Layout,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return import("@/views/system/passenger/edit");
    },
    name: "PassengerEdit",
    meta: {
      title: "用户详情"
    }
  }]
}, {
  path: "/driverDetail",
  component: Layout,
  hidden: true,
  children: [{
    path: "detail",
    component: function component() {
      return import("@/views/business/carpoolNew/driver/detail");
    },
    name: "driverDetail",
    meta: {
      title: "司机详情"
    }
  }]
}, {
  path: "/invoiceDetail",
  component: Layout,
  hidden: true,
  children: [{
    path: "detail",
    component: function component() {
      return import("@/views/financeCollect/orderInvoice/detail");
    },
    name: "invoiceDetail",
    meta: {
      title: "发票详情"
    }
  }]
}, {
  path: "/stationRankList",
  component: Layout,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return import("@/views/system/stationRankList/index");
    },
    name: "StationRankList",
    meta: {
      title: "站点排行榜"
    }
  }]
}, {
  path: "/count_detail",
  component: Layout,
  hidden: true,
  children: [{
    path: "edit",
    component: function component() {
      return import("@/views/system/dept/count_details");
    },
    name: "CountDetail",
    meta: {
      title: "结算记录"
    }
  }]
}, {
  path: "/scenic",
  component: Layout,
  hidden: true,
  children: [{
    path: "config",
    component: function component() {
      return import("@/views/business/jiyueTravel/scenicConfig");
    },
    name: "ScenicConfig",
    meta: {
      title: "景点信息配置"
    }
  }, {
    path: "orderDetail",
    component: function component() {
      return import("@/views/business/jiyueTravel/scenicOrderDetail");
    },
    name: "ScenicOrderDetail",
    meta: {
      title: "景点订单详情"
    }
  }]
}, {
  path: "/404",
  component: function component() {
    return import("@/views/error/404");
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return import("@/views/error/401");
  },
  hidden: true
}]; // 动态路由，基于用户权限动态去加载

export var dynamicRoutes = [{
  path: '/activity',
  component: Layout,
  hidden: true,
  permissions: ['activity:list'],
  children: [{
    path: 'actCoupon',
    component: function component() {
      return import('@/views/marketManage/activityNew/index');
    },
    name: 'activityCoupon',
    permissions: ['activity:coupon'],
    meta: {
      title: '优惠券活动',
      activeMenu: '/activity'
    }
  }, {
    path: 'busNum',
    component: function component() {
      return import('@/views/marketManage/activityBusNum/index');
    },
    name: 'activityBusNum',
    meta: {
      title: '乘车次数活动',
      activeMenu: '/activity'
    }
  }, {
    path: 'newUser',
    component: function component() {
      return import('@/views//marketManage/activityNewUser/index');
    },
    name: 'activityNewUser',
    meta: {
      title: '新用户活动',
      activeMenu: '/activity'
    }
  }]
}];
export default new Router({
  // mode: 'history', // 去掉url中的#
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});